import React from "react"
import { useScrollAnim } from "src/components/hooks/hooks"


import { Slider } from "components/anti/slider/slider"
import { Card } from "components/anti/card/card"

export const MilestonesHistory = ({ data }) => {
    const [trigger, anim] = useScrollAnim()

    return (
        <section className="py-main bg-light sc-milestones-history" ref={trigger}>
            <div className="container">
                <Slider
                    visibleInitial={true}
                    visibleXxlDown={true}
                    visibleXlDown={true}
                    visibleLgDown={true}
                    visibleMdDown={true}
                    dotsInitial={false}
                    dotsXxlDown={false}
                    dotsXlDown={false}
                    dotsLgDown={false}
                    dotsMdDown={false}
                    showInitial={4}
                    showXxlDown={3.3}
                    showXlDown={3.1}
                    showLgDown={2.5}
                    showMdDown={1.6}
                    showSmDown={1.4}
                    arrowsInitial={true}
                    arrowsXxlDown={true}
                    arrowsXlDown={true}
                    arrowsLgDown={true}
                    arrowsMdDown={false}
                    arrowsSmDown={true}
                    className="history-slider"
                >
                    {data.map((list, i) => {
                        return (
                            <Card
                                variant="boxless"
                                img={list.image.sourceUrl}
                                imgRatio="r-16-9"
                                cardBodyClassName="px-0"
                                className={`history ${anim(i + 1)}`}
                                key={i}
                            >
                                <div className="history-dot"></div>
                                <div className="history-line mb-4"></div>
                                <h4 className={`card-title`}>{list.title}</h4>
                                <div className="text-gray-600" dangerouslySetInnerHTML={{ __html: list.text }} />
                            </Card>
                        )
                    })}
                </Slider>
            </div>
        </section>
    )
}