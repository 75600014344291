import React from "react"
import { useScrollAnim } from "src/components/hooks/hooks"

export const MilestonesHeadline = ({ data }) => {
    const [trigger, anim] = useScrollAnim()

    return (
        <section className="py-main" ref={trigger}>
            <div className="container">
                <div className="w-md-600px">
                    <h2 className={`${anim(1)}`}>{data.title}</h2>
                    <p className={`text-gray-600 font-weight-light ${anim(2)}`}>{data.text}</p>
                </div>
            </div>
        </section>
    )
}