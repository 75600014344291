import React from "react"
import { useStaticQuery, graphql } from "gatsby"


import SEO from "components/seo"
import Layout from "components/layout"
import { GlobalCover } from "components/global/cover/cover"
import { MilestonesHeadline } from "components/pages/investor/milestones/headline"
import { MilestonesHistory } from "components/pages/investor/milestones/history"

const Milestones = ({ path, pageContext, location }) => {
  const data = useStaticQuery(GET_MILESTONES_DETAILS)
  const lang = pageContext.langKey
  const pageData = lang === "en"
    ? data?.wordPress?.page?.milestonePage
    : data?.wordPress?.page?.translation?.milestonePage
    || data?.wordPress?.page?.milestonePage


  const bgColor = lang === "en"
    ? data?.wordPress.page.backgroundColor.backgroundColor.color
    : data?.wordPress.page.translation.backgroundColor.backgroundColor.color
    || data?.wordPress.page.backgroundColor.backgroundColor.color

  const { seo } = lang === "en"
    ? data.wordPress.page
    : data.wordPress.page.translation
    || data.wordPress.page

  const SEODefaultImg = pageData.cover.image.sourceUrl

  return (
    <Layout path={path} theme={bgColor} lang={lang}>
      <SEO
        title={seo.title}
        desc={seo.metaDesc}
        fbImg={SEODefaultImg}
        fbTitle={seo.opengraphTitle}
        fbDesc={seo.opengraphDescription}
        url={location.href}
        twitterTitle={seo.twitterTitle}
        twitterDesc={seo.twitterDescription}
        twitterImg={SEODefaultImg}
      />
      <GlobalCover data={pageData.cover} theme={bgColor} breadcrumb={path} />
      <MilestonesHeadline data={pageData.headline} />
      <MilestonesHistory data={pageData.history} />
    </Layout>
  )
}

export default Milestones


const GET_MILESTONES_DETAILS = graphql`
query milestones {
    wordPress {
      page(id: "milestone", idType: URI) {
        backgroundColor {
          backgroundColor {
            color
          }
        }
        seo {
          title
          metaDesc
          twitterTitle
          twitterDescription
          opengraphTitle
          opengraphDescription
        }
        slug
        milestonePage {
          history {
            title
            text
            image {
              sourceUrl
              altText
            }
          }
          headline {
            title
            text
          }
          cover {
            title
            image {
              sourceUrl
              altText
            }
          }
        }
        translation(language: ID) {
          backgroundColor {
            backgroundColor {
              color
            }
          }
          seo {
            title
            metaDesc
            twitterTitle
            twitterDescription
            opengraphTitle
            opengraphDescription
          }
          slug
          milestonePage {
            history {
              title
              text
              image {
                sourceUrl
                altText
              }
            }
            headline {
              title
              text
            }
            cover {
              title
              image {
                sourceUrl
                altText
              }
            }
          }
        }
      }
    }
  }
  
`